import React, { Component } from 'react';

class GameEventDialog extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="dialog-background">
        <div className="easter-dialog">
          <img className="close-dialog" src="/game_event/closed.webp" onClick={this.props.closeDialog} />
          <h2>Gewinne im Wert von über 2.000 €</h2>
          <div className="row">
            <div className="col-md-4">
              <img src="/game_event/easter-eggs.webp" alt="rabbits" />
            </div>
            <div className="col-md-8">
              <ul>
                <li><span><img src="/game_event/green-icon1.webp" /></span><span>Täglich Gutscheine und Noozie Punkte gewinnen</span></li>
                <li>
                  <span><img src="/game_event/green-icon2.webp" /></span>
                  <span>
                    Sammle bis Aktionsende bis zu 100 Eier und nimm an der Verlosung der Hauptgewinne teil:
                    <ul>
                      <li><strong>1. Preis</strong> - (über 80 - 100 gesammelte Eier) - 10 x 3-Monats-Abo Classic Box, 10 x Schoko Candy Box, 5 x Candy & Snack Adventskalender</li>
                      <li><strong>2. Preis</strong> - (50 - 79 gesammelte Eier) - 15 x Cool Box Mai, 10 x XXL Deal April, 10 x Asia Box</li>
                    </ul>
                  </span>
                </li>
                <li><span><img src="/game_event/green-icon3.webp" /></span><span>Aktionszeitraum vom 01.04. - 21.04.2025</span></li>
              </ul>
            </div>
          </div>
          <div>
            <p className="centered">Wir verstecken jeden Tag 5 Ostereier für dich, die du in <a href={this.props.profilePath} target="_blank">deinem Profil</a> in einem virtuellen Osterkörbchen sammeln kannst! *</p>
          </div>
          <div className="centered">
            {
              this.props.signedIn ? <a href="" onClick={this.props.closeDialog} className="btn btn-green">Los geht's!</a> : <a href="/customers/sign_in" className="btn btn-green">Login / Registrierung</a>
            }
          </div>
          <div className="centered easter-footer">* Nur registrierte und eingeloggte Nutzer können Ostereier sammeln.</div>
          <div className="centered easter-footer">Mit deiner Teilnahme erklärst du dich mit unseren <a href="https://www.brandnooz.de/blog/teilnahmebedingungen-brandnooz-ostergewinnspiel-2025/" target="_blank">Teilnahmebedingungen</a> einverstanden.</div>
        </div>
      </div>
    );
  }
}

export default GameEventDialog;
